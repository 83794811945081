import {Flex, Heading, Highlight, Text} from "@chakra-ui/react";
import React from 'react';

const Illustration = ({height = "727", width = "939.00197", style = {}}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            data-name="Layer 1"
            width="100%"
            height="100%"
            viewBox="0 0 939.00197 727"
            style={{...style, maxWidth: width, maxHeight: height}}
        >
            <path
                d="M957.89624,499.11854l20.53387-20.54508a365.6138,365.6138,0,0,0-7.9003-40.44615l-11.70253,5.92375L969.3407,433.532c-4.35756-16.43235-8.28368-27.03826-8.28368-27.03826s-41.38258,42.14412-62.771,91.56442l13.25224,34.839L893.40655,510.501a147.91712,147.91712,0,0,0-5.08407,17.88019c-13.08622,61.15052-3.72315,114.99673,20.91306,120.26888s55.21623-40.02623,68.30244-101.17675c4.05686-18.95727,4.01248-39.70853,2.02255-59.32119Z"
                transform="translate(-130.49901 -86.5)" fill="#e6e6e6"/>
            <path
                d="M1018.91732,541.79231l28.1977-6.97352a365.61432,365.61432,0,0,0,14.14365-38.7074l-13.07988-.97806,14.43728-3.5704c4.76364-16.31925,6.88492-27.42781,6.88492-27.42781s-57.20972,14.68655-101.06463,45.93682l-6.66382,36.67387-3.94492-28.54439a147.91789,147.91789,0,0,0-13.59449,12.67829C901.422,576.463,881.604,627.39757,899.9685,644.64526s67.95719-5.72291,110.76837-51.30622c13.27188-14.13127,23.96025-31.91822,32.39459-49.73614Z"
                transform="translate(-130.49901 -86.5)" fill="#e6e6e6"/>
            <path
                d="M749.79645,288.47681l6.92734-54.36936A689.87488,689.87488,0,0,0,698.238,182.8631l-10.62691,22.35156,3.54692-27.83718c-25.47458-19.49424-43.57867-30.79072-43.57867-30.79072s-13.10778,110.67522,12.02559,209.127l60.00009,36.697-52.91735-12.493a279.10646,279.10646,0,0,0,13.05464,32.55539c51.06678,106.374,127.206,175.929,170.06174,155.35529s36.19934-123.48511-14.86744-229.85913c-15.8312-32.977-39.85459-63.89666-65.46716-90.87087Z"
                transform="translate(-130.49901 -86.5)" fill="#e6e6e6"/>
            <path
                d="M890.13585,281.71839l34.03372-42.96187a689.87673,689.87673,0,0,0-23.57819-74.09885l-20.65066,13.64087,17.4254-21.99646c-11.73079-29.85579-21.38955-48.88412-21.38955-48.88412s-68.429,87.96754-97.80357,185.23812l32.394,62.42846-38.842-38.04763a279.10535,279.10535,0,0,0-5.65257,34.61683C754.80306,469.11118,784.02857,568.00981,831.34961,572.55s94.81789-86.99732,106.08731-204.45475c3.49363-36.413-1.08905-75.29931-9.07156-111.62962Z"
                transform="translate(-130.49901 -86.5)" fill="#e6e6e6"/>
            <path
                d="M262.73388,296.46743l-3.44273-57.04766a719.35885,719.35885,0,0,0-69.85662-41.1647l-6.55477,24.96069-1.76257-29.20849c-29.87946-15.03445-50.61818-23.09865-50.61818-23.09865s8.02614,115.93457,52.863,211.9319l68.58793,25.96655-56.639-2.54113A291.03672,291.03672,0,0,0,214.9975,437.0901c72.943,99.08666,164.43636,155.58934,204.35621,126.2022s13.14921-133.53569-59.7938-232.62235c-22.61306-30.71785-53.22065-57.73966-84.69159-80.411Z"
                transform="translate(-130.49901 -86.5)" fill="#e6e6e6"/>
            <path
                d="M405.20989,262.33637l26.54088-50.61491a719.35842,719.35842,0,0,0-38.52232-71.34782l-18.51339,17.9793,13.58907-25.91485C370.49729,104.12319,356.91242,86.5,356.91242,86.5s-53.056,103.39393-64.29483,208.74818L337.9098,352.9299l-47.172-31.45208a291.03471,291.03471,0,0,0,.91954,36.56288c11.22446,122.527,60.34057,218.18885,109.704,213.66676S481.64245,464.19175,470.418,341.66477c-3.4797-37.98457-15.71355-76.93757-30.93523-112.61266Z"
                transform="translate(-130.49901 -86.5)" fill="#e6e6e6"/>
            <ellipse cx="423.19348" cy="678" rx="35" ry="10" fill="#e6e6e6"/>
            <ellipse cx="804.19348" cy="717" rx="35" ry="10" fill="#e6e6e6"/>
            <ellipse cx="877.19348" cy="635" rx="35" ry="10" fill="#e6e6e6"/>
            <ellipse cx="733.69348" cy="608.42857" rx="26.5" ry="7.57143" fill="#e6e6e6"/>
            <ellipse cx="159.69348" cy="603.42857" rx="17.5" ry="5.42857" fill="#e6e6e6"/>
            <ellipse cx="90.69348" cy="664.42857" rx="17.5" ry="5.42857" fill="#e6e6e6"/>
            <ellipse cx="90.69348" cy="568.42857" rx="17.5" ry="5.42857" fill="#e6e6e6"/>
            <path
                d="M970.56443,672.45445l-169.56-6-89.42,8.53-152.77,14.57-57.49,5.48-133.14,44.4a2.9799,2.9799,0,0,1-3.73-3.88l.01-.01,23.09-61.07-2.43-1.79-63.54-46.69-.34-.25-82.39-12.56a6.90906,6.90906,0,0,1-4.62-10.78l125.48-180.06,65.94-94.6,7.15-10.27,274.3-3.79,267.81,350.35A5.24083,5.24083,0,0,1,970.56443,672.45445Z"
                transform="translate(-130.49901 -86.5)" fill="#f5cb5c"/>
            <polygon
                points="366.56 245.587 254.623 586.185 191.09 539.495 229.218 335.847 294.966 241.019 295.153 241.24 366.56 245.587"
                opacity="0.2"/>
            <path
                d="M397.14108,462.56463c-12.70125,30.53362-17.19787,56.037-32.26346,51.43166-20.62571-6.305,1.03059-28.60675,13.73184-59.14037s21.67557-53.95546,32.26346-51.43166C430.6121,408.12942,409.84233,432.031,397.14108,462.56463Z"
                transform="translate(-130.49901 -86.5)" fill="#3f3d56"/>
            <path
                d="M425.9181,401.44663a15.38772,15.38772,0,0,0-15.4125-15.363c-.3471.00056-.69041.01676-1.03183.03993l-14.52089-1.79137a15.35636,15.35636,0,0,0-5.1916-.89132c-.48854.00079-.97084.027-1.44772.07224l-.55938-.069-.015.13653a15.38817,15.38817,0,0,0-3.2515,29.6985l-.03026.27682,1.42849.16a15.40645,15.40645,0,0,0,3.92485.50044c.14684-.00023.292-.00745.43789-.01177l14.84426,1.66267a15.34594,15.34594,0,0,0,5.46217.99187,15.50638,15.50638,0,0,0,2.07947-.14717l1.26569.14179.00765-.37088A15.39024,15.39024,0,0,0,425.9181,401.44663Z"
                transform="translate(-130.49901 -86.5)" fill="#f5cb5c"/>
            <path
                d="M425.9181,401.44663a15.38772,15.38772,0,0,0-15.4125-15.363c-.3471.00056-.69041.01676-1.03183.03993l-14.52089-1.79137a15.35636,15.35636,0,0,0-5.1916-.89132c-.48854.00079-.97084.027-1.44772.07224l-.55938-.069-.015.13653a15.38817,15.38817,0,0,0-3.2515,29.6985l-.03026.27682,1.42849.16a15.40645,15.40645,0,0,0,3.92485.50044c.14684-.00023.292-.00745.43789-.01177l14.84426,1.66267a15.34594,15.34594,0,0,0,5.46217.99187,15.50638,15.50638,0,0,0,2.07947-.14717l1.26569.14179.00765-.37088A15.39024,15.39024,0,0,0,425.9181,401.44663Z"
                transform="translate(-130.49901 -86.5)" fill="#fff" opacity="0.2"/>
            <path
                d="M220.41256,611.65282l-1.77732-.93275c45.52462-86.769,174.28467-272.16153,175.57989-274.024l1.64829,1.14595C394.56886,339.70351,265.8792,524.99394,220.41256,611.65282Z"
                transform="translate(-130.49901 -86.5)" fill="#3f3d56"/>
            <path
                d="M359.86062,741.68764l-1.93608-.5274c.93789-3.448,94.18572-345.49023,126.56132-403.18937l1.75.98179C453.98074,396.43828,360.79852,738.24165,359.86062,741.68764Z"
                transform="translate(-130.49901 -86.5)" fill="#3f3d56"/>
            <path
                d="M982.806,675.37424c-49.71216-81.89242-237.44721-335.51737-239.3382-338.06992l1.61317-1.19431c1.891,2.55287,189.681,256.25222,239.44037,338.22264Z"
                transform="translate(-130.49901 -86.5)" fill="#3f3d56"/>
            <ellipse cx="356.2328" cy="741.75985" rx="7.02485" ry="9.03195"
                     transform="translate(-131.69188 -85.92594) rotate(-0.09218)" fill="#3f3d56"/>
            <ellipse cx="220.85443" cy="606.50106" rx="7.02485" ry="9.03195"
                     transform="translate(-131.47445 -86.14391) rotate(-0.09218)" fill="#3f3d56"/>
            <ellipse cx="984.65329" cy="670.84958" rx="7.02485" ry="9.03195"
                     transform="translate(-131.57699 -84.91504) rotate(-0.09218)" fill="#3f3d56"/>
            <path
                d="M386.41377,346.65818l378.6212,4.05714A117.21182,117.21182,0,0,0,647.769,234.50015l-144.496.23247c-60.439.09723-111.61531,47.36184-116.50741,107.60241Z"
                transform="translate(-130.49901 -86.5)" fill="#3f3d56"/>
            <path
                d="M719.08445,471.81443h-138.38a12.71915,12.71915,0,0,0-12.73,12.88l.12,10.12,1.23,108.34.02,2.09.04,3.45.04,3.37.23,20.31a12.73876,12.73876,0,0,0,12.46,12.59c.34,0,.69-.01,1.04-.03l32.86-2,47.54-2.9,16.78-1.01995,3.74-.23005,26.15-1.59h.01l9.62-.59a12.7497,12.7497,0,0,0,11.96-12.71v-139.35A12.74356,12.74356,0,0,0,719.08445,471.81443Z"
                transform="translate(-130.49901 -86.5)" opacity="0.2"/>
            <path
                d="M747.58079,454.11943a3.40125,3.40125,0,0,0-2.519,1.44415l-15.24732.02453v-4.0437a12.74356,12.74356,0,0,0-12.73-12.73h-138.38a12.71915,12.71915,0,0,0-12.73,12.88l.04931,4.15722-9.83355.01581a3.04326,3.04326,0,0,0-2.06507-.95678c-3.44653.00555-6.22845,7.53228-6.2135,16.81146s2.821,16.79693,6.26758,16.79144a3.76589,3.76589,0,0,0,2.88837-1.92487l188.04364-.30249a3.401,3.401,0,0,0,2.52368,1.436c3.44653-.00549,6.22845-7.53229,6.2135-16.81146S751.02738,454.11387,747.58079,454.11943Z"
                transform="translate(-130.49901 -86.5)" fill="#f5cb5c"/>
            <path
                d="M747.58079,454.11943a3.40125,3.40125,0,0,0-2.519,1.44415l-15.24732.02453v-4.0437a12.74352,12.74352,0,0,0-12.73-12.73h-138.38a12.71935,12.71935,0,0,0-12.7301,12.88l.04931,4.15722-9.83349.01581a3.04337,3.04337,0,0,0-2.06507-.95678c-3.44653.00555-6.22839,7.53228-6.2135,16.81146s2.82105,16.79693,6.26758,16.79144a3.766,3.766,0,0,0,2.88843-1.92487l188.04358-.30249a3.401,3.401,0,0,0,2.52368,1.436c3.44653-.00549,6.22839-7.53229,6.2135-16.81146S751.02732,454.11387,747.58079,454.11943Z"
                transform="translate(-130.49901 -86.5)" fill="#fff" opacity="0.2"/>
            <path
                d="M732.08986,666.55812l0,0a8.13339,8.13339,0,0,1-12.77-2.18249L711.7966,649.931l2.99307-6.485,16.12814,10.322A8.13338,8.13338,0,0,1,732.08986,666.55812Z"
                transform="translate(-130.49901 -86.5)" fill="#ffb8b8"/>
            <path
                d="M764.5875,750.83468a6.73552,6.73552,0,0,0-6.79074-6.01832,6.82,6.82,0,0,0-6.66261,5.16537h0l-6.66262,32.35394a13.0879,13.0879,0,0,0-.34045,2.8847c0,7.29592,6.10618,12.3661,13.66569,12.3661s13.66934-5.07018,13.66934-12.3661a13.23738,13.23738,0,0,0-.47225-3.45944Z"
                transform="translate(-130.49901 -86.5)" fill="#3f3d56"/>
            <path
                d="M814.98541,765.30573a15.29421,15.29421,0,0,0-2.17817-1.59244l-25.45338-17.78771c-2.47834-1.55583-5.12508-1.5119-7.047.36608a4.953,4.953,0,0,0-.73216,6.29654l19.21908,25.25936a13.38792,13.38792,0,0,0,1.46431,1.7169c3.33131,3.723,10.71876,3.928,14.77856,0C819.04156,775.57055,818.994,768.454,814.98541,765.30573Z"
                transform="translate(-130.49901 -86.5)" fill="#3f3d56"/>
            <path
                d="M834.113,738.95547H808.33379a3.13509,3.13509,0,0,0-3.25078,2.98719,3.40794,3.40794,0,0,0,2.53327,3.35328l24.31854,7.52289c3.53264.82367,8.29165-2.3246,8.29165-6.25627C840.22647,741.56194,837.44428,738.95547,834.113,738.95547Z"
                transform="translate(-130.49901 -86.5)" fill="#3f3d56"/>
            <path
                d="M711.44766,741.928a3.20085,3.20085,0,0,0-3.45943-2.9872H682.209c-3.24711,0-5.98537,3.507-5.98537,7.4387s4.6199,7.263,8.16353,6.43931l24.68462-7.52289A3.097,3.097,0,0,0,711.44766,741.928Z"
                transform="translate(-130.49901 -86.5)" fill="#3f3d56"/>
            <path
                d="M729.1402,745.95487l-25.46071,17.71084a11.83915,11.83915,0,0,0-2.19646,1.59244,9.63883,9.63883,0,0,0-.49354,13.62242q.23775.25561.49354.49354a10.759,10.759,0,0,0,14.77489,0,11.25814,11.25814,0,0,0,1.46432-1.71324l19.23371-25.1166a4.82754,4.82754,0,0,0-.73215-6.24528A5.49116,5.49116,0,0,0,729.1402,745.95487Z"
                transform="translate(-130.49901 -86.5)" fill="#3f3d56"/>
            <path
                d="M768.744,711.3427a2.73473,2.73473,0,0,0,.47225-1.47021c0-3.10066-6.10984-5.25542-13.66934-5.25542s-13.66568,2.15476-13.66568,5.25542a2.6173,2.6173,0,0,0,.34045,1.226l6.66261,13.75h0c.76335,1.29732,3.51607,2.20429,6.66261,2.19521,3.5473,0,6.49422-1.12172,6.79075-2.5577Z"
                transform="translate(-130.49901 -86.5)" fill="#3f3d56"/>
            <path
                d="M812.78676,712.27616c-4.0598-1.66935-11.44725-1.58222-14.77855,0a11.98623,11.98623,0,0,0-1.46432.72966l-19.21907,10.73486c-1.30683.85025-.99886,1.97581.73215,2.67594a11.49937,11.49937,0,0,0,7.047.15558l25.45338-7.55952a21.70208,21.70208,0,0,0,2.17816-.67676C816.74407,716.99795,816.79166,713.97352,812.78676,712.27616Z"
                transform="translate(-130.49901 -86.5)" fill="#3f3d56"/>
            <path
                d="M837.97658,726.30149c0-1.6709-4.759-3.00887-8.29166-2.65882l-24.31854,3.19712c-1.45333.2038-2.53326.67676-2.53326,1.42509s1.58512,1.26951,3.25077,1.26951h25.77918C835.19438,729.53439,837.97658,728.42668,837.97658,726.30149Z"
                transform="translate(-130.49901 -86.5)" fill="#3f3d56"/>
            <path
                d="M706.82192,726.83979l-24.68462-3.19712c-3.54363-.35-8.16353,1.0657-8.16353,2.73661s2.73826,3.16134,5.98537,3.16134h25.77919c1.79378,0,3.45944-.49163,3.45944-1.26952S708.429,727.04359,706.82192,726.83979Z"
                transform="translate(-130.49901 -86.5)" fill="#3f3d56"/>
            <path
                d="M733.9739,726.4135c1.8011-.711,2.01343-1.786.73216-2.65415l-19.23372-10.67419a10.09729,10.09729,0,0,0-1.46431-.7281,22.48479,22.48479,0,0,0-14.7749,0q-.25561.101-.49353.20975c-3.62544,1.6566-3.40448,4.24857.49353,5.78933a16.885,16.885,0,0,0,2.19647.67676l25.4607,7.52685A11.63414,11.63414,0,0,0,733.9739,726.4135Z"
                transform="translate(-130.49901 -86.5)" fill="#3f3d56"/>
            <path
                d="M807.978,709.11687c0,39.76268-22.16089,40.49815-49.49774,40.49815s-72.21839-7.86622-49.49773-40.49815C732.94632,674.69944,774.057,609.0961,758.48021,605.6216,758.48021,605.6216,807.978,669.35419,807.978,709.11687Z"
                transform="translate(-130.49901 -86.5)" fill="#f9a825"/>
            <path
                d="M775.91267,733.5845c0,15.73939-8.772,16.03052-19.59285,16.03052S727.73337,746.5013,736.727,733.5845c9.48569-13.62356,25.75867-39.59156,19.59286-40.96688C756.31982,692.61762,775.91267,717.8451,775.91267,733.5845Z"
                transform="translate(-130.49901 -86.5)" fill="#ff6584" opacity="0.5"/>
            <path
                d="M731.81443,616.7844v7.11a12.7497,12.7497,0,0,1-11.96,12.71l-136.7,8.33a12.72239,12.72239,0,0,1-13.5-12.56l-.33-29.22,82.69-1.34Z"
                transform="translate(-130.49901 -86.5)" fill="#3f3d56"/>
            <path
                d="M709.523,652.00232l-61.67123-32.0308a2.2578,2.2578,0,0,1-1.15069-1.45994L633.323,564.594a12.94227,12.94227,0,0,1,8.2859-15.3324l3.22289-1.128a5.56214,5.56214,0,0,1,7.19667,3.7612l13.18592,47.46934a2.25787,2.25787,0,0,0,.73491,1.13426L716.197,642.13213a2.25778,2.25778,0,0,1,.42286,3.01348l-4.19284,6.128A2.25778,2.25778,0,0,1,709.523,652.00232Z"
                transform="translate(-130.49901 -86.5)" fill="#e6e6e6"/>
            <path
                d="M668.8959,568.12037c-29.911-15.74716-61.221-23.6552-94.78063-18.95613,10.116-17.14469,17.62946-32.99557,17.12706-44.8618a23.35731,23.35731,0,0,1,9.16341-19.89849,28.35484,28.35484,0,0,1,16.89891-5.57691h0a23.22147,23.22147,0,0,1,17.2-.68383,18.08621,18.08621,0,0,1,10.49021,10.38822c1.14735,2.77958,3.61729,5.2146,7.20024,7.35968a20.2048,20.2048,0,0,1,7.796,8.14483c2.36,4.69706,2.52253,9.42534-.57055,14.1999C671.00262,530.40331,671.035,548.68685,668.8959,568.12037Z"
                transform="translate(-130.49901 -86.5)" fill="#2f2e41"/>
            <circle cx="495.99502" cy="421.26007" r="20.95151" fill="#ffb8b8"/>
            <path
                d="M636.471,553.155l-24.44343,2.49423c1.31154-14.29166,1.82786-27.47949,0-37.4134H636.471C631.82867,534.05205,632.236,545.32529,636.471,553.155Z"
                transform="translate(-130.49901 -86.5)" fill="#ffb8b8"/>
            <path
                d="M649.56443,552.61442q-.03,3.285-.14,6.5c-.51,15.34-2.44,29.15-7.7,39.32a35.33535,35.33535,0,0,1-3.81,5.95.175.175,0,0,1-.04.05,3.86448,3.86448,0,0,1-.33.4,28.29628,28.29628,0,0,1-3.57,3.69l-10.01,10.5-.46.48005-2.92,1.95-15.04,10.02-36.12-19.41-.04-3.37-.04-3.45c8.03-11.38,13.04-26.18,16.22-43.03.87-4.61,1.61-9.38,2.24-14.28.43-3.39.81-6.85,1.14-10.36a5.53476,5.53476,0,0,1,1.05-2.99.00976.00976,0,0,1,.01-.01c1.54-1.96,4.6-1.92,8.3-2.12,5.19-.28,10.3-2.97,14.89-.43006a2.69536,2.69536,0,0,1,.33.18006l13.47,14.96,6.19-6.67.29-.31,12.75,5.46a5.88655,5.88655,0,0,1,3.34,6.97Z"
                transform="translate(-130.49901 -86.5)" fill="#e6e6e6"/>
            <path
                d="M621.13444,626.14439a20.2304,20.2304,0,0,1-1.42,7.45l-3.7,9.34-32.86,2c-.35.02-.7.03-1.04.03a12.73876,12.73876,0,0,1-12.46-12.59l-.23-20.31-.04-3.37,11.96,5.07,11.66-6.26a17.44931,17.44931,0,0,1,1.95-.7,18.75366,18.75366,0,0,1,2.64-.63h.01c.43-.07.86005-.13,1.29-.17a20.21134,20.21134,0,0,1,13.91,3.76995,19.94542,19.94542,0,0,1,5.71,6.42,4.74519,4.74519,0,0,1,.29.54,19.51246,19.51246,0,0,1,1.78,4.72A20.458,20.458,0,0,1,621.13444,626.14439Z"
                transform="translate(-130.49901 -86.5)" fill="#2f2e41"/>
            <polygon points="481.279 479.874 485.65 499.929 512.207 522.775 488.762 498.831 481.279 479.874"
                     opacity="0.2"/>
            <polygon points="456.836 489.352 464.817 521.777 488.263 530.258 466.314 519.283 456.836 489.352"
                     opacity="0.2"/>
            <path
                d="M651.68573,509.00719c-15.44159-7.10428-32.89948-9.12983-48.388-1.49654l1.99538-17.95843,33.92148-5.4873Z"
                transform="translate(-130.49901 -86.5)" fill="#2f2e41"/>
            <polygon points="645.315 580.314 531.768 560.808 533.16 553.948 645.315 580.314 645.315 580.314"
                     fill="#3f3d56"/>
            <path
                d="M681.20763,651.59276l0,0a8.13339,8.13339,0,0,1-12.77-2.18249l-7.52325-14.44464,2.99307-6.485,16.12814,10.322A8.13338,8.13338,0,0,1,681.20763,651.59276Z"
                transform="translate(-130.49901 -86.5)" fill="#ffb8b8"/>
            <path
                d="M658.64078,637.037l-61.67123-32.0308a2.25777,2.25777,0,0,1-1.15069-1.45994l-13.378-53.91762a12.94228,12.94228,0,0,1,8.2859-15.33241l3.22289-1.128a5.56214,5.56214,0,0,1,7.19667,3.7612l13.18592,47.46933a2.25779,2.25779,0,0,0,.73491,1.13426l50.24768,41.6338a2.25778,2.25778,0,0,1,.42286,3.01347l-4.19284,6.128A2.25778,2.25778,0,0,1,658.64078,637.037Z"
                transform="translate(-130.49901 -86.5)" fill="#e6e6e6"/>
            <path
                d="M763.57518,670.36521a2.105,2.105,0,0,0,2.46679-1.6455l1.1377-5.69434a2.09639,2.09639,0,0,0-1.64453-2.4668l-16.48145-3.29492a2.1013,2.1013,0,0,0-2.4668,1.64551l-1.13769,5.69434a2.09636,2.09636,0,0,0,1.64453,2.46679Z"
                transform="translate(-130.49901 -86.5)" fill="#e6e6e6"/>
        </svg>)
}

const NewFeatureEmptyState = ({text}: { text: string }) => {
    return (
        <Flex p={4} mt={12} direction={"column"} alignItems={"center"} gap={4}>
            <Illustration height="400" width="500" style={{width: '100%', height: 'auto'}}/>
            <Heading mt={6} textAlign={"center"}>
                <Highlight query='por enquanto' styles={{px: '1', py: '1', bg: 'yellow.100'}}>
                    Aqui, por enquanto, tudo é mato!
                </Highlight>
            </Heading>
            <Text textAlign={"center"} width={"80%"}>{text}</Text>
        </Flex>
    );
};

export default NewFeatureEmptyState;
