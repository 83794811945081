import {Box} from "@chakra-ui/react";
import NewFeatureEmptyState from "components/NewFeatureEmptyState";

const FifaTrendModule = () => {

    return (
        <Box mt={4}>
            <NewFeatureEmptyState text={"Em breve erá possível visualizar as tendências de mercado neste módulo."}/>
        </Box>
    );
};

export default FifaTrendModule;
