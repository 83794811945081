export enum FEATURES {
    FIFA_STRATEGY = "fifa-strategy",
    MESSENGER_CHAT = "messenger-chat",
    TREND_MODULE = "trend-module",
    BACKTEST_MODULE = "backtest-module"
}

export const FEATURE_LABELS = {
    [FEATURES.FIFA_STRATEGY]: "Estratégia de E-Soccer",
    [FEATURES.MESSENGER_CHAT]: "Chat do Telegram",
    [FEATURES.TREND_MODULE]: "Módulo de Tendências",
    [FEATURES.BACKTEST_MODULE]: "Módulo de Backtest"
};
